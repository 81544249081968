import { Component, OnInit, Output, ViewEncapsulation, EventEmitter } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Subject, takeUntil } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { LasService } from "../core/services/las.service";
import { environment } from "src/environments/environment";
import { RoutingOrderService } from "src/app/core/services/routing-order.service";

@Component({
	selector: "app-status",
	templateUrl: "./status.component.html",
	styleUrl: "status.component.scss",
	encapsulation: ViewEncapsulation.None,
})
export class StatusComponent implements OnInit {
	unsubscriber: Subject<any> = new Subject<any>();
	backendURL = environment.backendURL;

	// LAS Log
	lasData: any;

	// Campaign status
	isCampaignActif: boolean = false;
	isCampaignUpToDate: boolean = false;

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private lasService: LasService,
		private routingOrderService: RoutingOrderService
	) {}

	// --------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// --------------------------------------------------------------------------------------------
	ngOnInit(): void {
		this.lasService.campaignSubject.pipe(takeUntil(this.unsubscriber)).subscribe((data: any) => {
			if (data != null) {
				// Check the campaign status
				if (!data.campaign.isActif) {
					this.isCampaignActif = true;
				} else {
					this.isCampaignUpToDate = true;
				}
			}
		});
	}

	ngOnDestroy(): void {
		this.unsubscriber.next(null);
		this.unsubscriber.complete();
	}

	// --------------------------------------------------------------------------------------------
	// @ Events
	// --------------------------------------------------------------------------------------------
}
