// routing-order.service.ts
import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject, takeUntil } from "rxjs";
import { LasService } from "src/app/apps/las/core/services/las.service";

@Injectable({
	providedIn: "root",
})
export class RoutingOrderService {
	unsubscriber: Subject<any> = new Subject<any>();
	private routeOrder: string[] = [];
	private routeOrderKeys: string[] = [];

	backendSteps: any[];
	backendSlug: string;

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private lasService: LasService
	) {
		// Extract the route order from the backend data
		this.lasService.campaignSubject.pipe(takeUntil(this.unsubscriber)).subscribe((data: any) => {
			if (data != null) {
				this.backendSteps = data.campaign.steps;
				this.backendSlug = data.campaign.slug;

				const stepToRouteMap = {
					"screen.policy": "policy",
					"screen.tutorial": "tutorial",
					"screen.game-chooser": "game-chooser",
					"screen.content": "content",
					"screen.data-collection": "data-collection",
					"screen.qa": "Q&A",
					"wheel.wheel": "wheel",
					"game-reveal-touch.reveal-touch": "reveal-touch",
					"game-spot-mlb.spot-mlb": "spot-mlb",
					"game-choose-venue.choose-venue": "choose-venue",
					"game-friend-name.friend-name": "friend-names",
					"game-friend-preference.friend-preference": "friend-preferences",
				};

				// Return steps and games as value
				this.routeOrder = this.backendSteps.map((step) => stepToRouteMap[step.__component]);

				// Return steps and games as key
				this.routeOrderKeys = this.backendSteps.map((step) => {
					const route = stepToRouteMap[step.__component];
					return Object.keys(stepToRouteMap).find((key) => stepToRouteMap[key] === route);
				});

				function processRoutesByGames(routes: any) {
					// Count games by checking the keys
					const gamesCount = routes.filter((key: any) => key.startsWith("game-")).length;

					// After checking keys, always return values but based on different conditions
					if (gamesCount === 0) {
						// If no game keys, return all values
						localStorage.setItem("gamesType", "mono");
						return routes.map((key: any) => stepToRouteMap[key]);
					}

					if (gamesCount === 1) {
						// If one game key, return all values
						localStorage.setItem("gamesType", "mono");
						return routes.map((key: any) => stepToRouteMap[key]);
					}

					if (gamesCount > 1) {
						// If multiple game keys, return values but exclude those whose keys start with 'game-'
						localStorage.setItem("gamesType", "multi");
						return routes.filter((key: any) => !key.startsWith("game-")).map((key: any) => stepToRouteMap[key]);
					}
				}

				this.routeOrder = processRoutesByGames(this.routeOrderKeys);

				localStorage.setItem("routeOrder", JSON.stringify(this.routeOrder));

				if (this.router.url.endsWith(`/las/${this.backendSlug}`) && this.routeOrder[0]) {
					this.router.navigate([`/las/${this.backendSlug}/${this.routeOrder[0]}`], { relativeTo: this.activatedRoute });
				}
			}
		});
	}

	callThisService() {}

	getNextRoute(currentPath: string): string | null {
		// Get the index of the current path in route order
		const currentIndex = this.routeOrder.indexOf(currentPath);
		// Check if is next route available
		if (currentIndex < this.routeOrder.length - 1) {
			return this.routeOrder[currentIndex + 1];
		}
		return null;
	}

	navigateToNext(currentPath: string): void {
		const nextRoute = this.getNextRoute(currentPath);

		if (nextRoute) {
			this.router.navigate([`/las/${this.backendSlug}/${nextRoute}`], { relativeTo: this.activatedRoute });
		} else {
			this.router.navigate([`/las/${this.backendSlug}/${this.routeOrder[0]}`], { relativeTo: this.activatedRoute });
		}
	}
}
