import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject, takeUntil } from "rxjs";
import { LasService } from "../core/services/las.service";
import { environment } from "src/environments/environment";

import { DeviceUUID } from "device-uuid";
import { RestApiService } from "src/app/core/services/rest-api.service";
import { RoutingOrderService } from "src/app/core/services/routing-order.service";
import { ThemeService } from "../core/services/theme.service";
import { RouteOrderGuard } from "../core/guards/route.guard";
import { RoutePositionService } from "../core/services/route.position.service";

@Component({
	selector: "app-content",
	templateUrl: "./content.component.html",
	styleUrl: "./content.component.scss",
	encapsulation: ViewEncapsulation.None,
})
export class ContentComponent implements OnInit {
	@ViewChild("videoPlayer") videoPlayer: ElementRef<HTMLVideoElement>;
	videoLoaded = false;

	unsubscriber: Subject<any> = new Subject<any>();
	backendURL = environment.backendURL;
	ngStyle: any;
	isOnline = true;
	isStandalone = false;
	footerBtnText: string;
	campaignHeader: any;
	footerText: any;

	uuid: string = "";

	// LAS Log
	lasData: any;
	stepName: string;
	contentLogVerfication: any;

	// Content
	contentHeaderOtp: boolean;
	contentFooterOtp: boolean;

	contentData: any;
	contentDataType: string;

	contentTextdata: any;
	contentImgdata: any;
	contentVideodata: any;

	backgroundImageUrl: string;

	// Final Step
	isFinalStep: boolean = false;

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private lasService: LasService,
		private restApiService: RestApiService,
		private routingOrderService: RoutingOrderService,
		private themeService: ThemeService,
		private routeGuard: RouteOrderGuard,
		private routePositionService: RoutePositionService
	) {}

	// --------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// --------------------------------------------------------------------------------------------
	ngOnInit(): void {
		this.routeGuard.markRouteAsVisited("content");

		// Check if this route is the final step
		this.isFinalStep = this.routePositionService.getRouteOrderPosition("content");

		this.uuid = new DeviceUUID().get();
		this.initializeLogVerfication();
		this.videoLoaded = false;

		this.lasService.campaignSubject.pipe(takeUntil(this.unsubscriber)).subscribe((data: any) => {
			if (data != null) {
				this.campaignHeader = data.campaign.theme?.header;
				this.footerText = data.campaign.theme?.footer;

				this.contentData = data.campaign.steps.filter((e) => e.__component === "screen.content")[0];
				this.contentHeaderOtp = this.contentData?.isHeaderEnabled;
				this.contentFooterOtp = this.contentData?.isFooterEnabled;

				// this.contentDataType = this.contentData?.type;
				this.contentDataType = "none";

				this.contentTextdata = this.contentData?.richText;
				this.contentImgdata = this.backendURL + this.contentData.img?.url;
				this.contentVideodata = this.backendURL + this.contentData.video?.url;

				// Log step
				const stepLebelFormated = this.removeSpacesBetweenWords(this.contentData.stepLabel);
				if (!this.contentLogVerfication[stepLebelFormated]) {
					this.stepName = this.contentData?.stepLabel;
					this.lasService.lasSubject.pipe(takeUntil(this.unsubscriber)).subscribe((data) => {
						if (data != null) {
							this.lasData = data;
							this.logStep(this.lasData.id, this.stepName, { ...(this.isFinalStep && { isFinalStep: true }) });
						}
					});

					this.updateLogVerfication(this.removeSpacesBetweenWords(this.stepName), "true");
				}

				this.footerBtnText = this.contentData?.ctaText;

				// Set theme --------------------
				const theme = data.campaign?.theme;
				const { ngStyle, backgroundImageUrl } = this.themeService.getThemeStyles(theme, this.contentData);
				this.ngStyle = ngStyle;
				this.backgroundImageUrl = backgroundImageUrl;
			}
		});
	}

	ngAfterViewChecked() {
		this.playVideoIfNeeded();
	}

	ngOnDestroy(): void {
		this.unsubscriber.next(null);
		this.unsubscriber.complete();
	}

	// --------------------------------------------------------------------------------------------
	// @ Events
	// --------------------------------------------------------------------------------------------
	onFooterBtnClick() {
		// If is final step remove the log verfication
		if (this.isFinalStep) {
			localStorage.removeItem("logVerfication");
			localStorage.removeItem("visitedRoutes");
		}

		const currentPath = this.activatedRoute.snapshot.routeConfig.path;
		this.routingOrderService.navigateToNext(currentPath);
	}

	onVideoLoaded() {
		const videoElement = document.querySelector(".video-content") as HTMLElement;
		if (videoElement) {
			videoElement.style.border = "1px solid var(--campaign-color)";
		}
	}

	// --------------------------------------------------------------------------------------------
	// @ Function
	// --------------------------------------------------------------------------------------------
	logStep(lasId: number, stepLabel: string, additionalData = {}) {
		this.lasService
			.logStep(lasId, stepLabel, additionalData)
			.pipe(takeUntil(this.unsubscriber))
			.subscribe({
				next: (response) => {
					console.log("Journey content created:", response);
				},
				error: (error) => {
					console.error("Error creating journey:", error);
				},
			});
	}

	// Initialize LogVerfication & Update the component log
	private initializeLogVerfication() {
		const storedState = localStorage.getItem("logVerfication");
		this.contentLogVerfication = storedState ? JSON.parse(storedState) : {};
	}
	private updateLogVerfication(key: string, value: any) {
		this.contentLogVerfication[key] = value;
		localStorage.setItem("logVerfication", JSON.stringify(this.contentLogVerfication));
	}

	// Remove spaces from the text
	removeSpacesBetweenWords(text: any) {
		return text.replace(/(\S)\s+(\S)/g, "$1$2");
	}

	private playVideoIfNeeded() {
		if (this.contentData?.type === "video" && this.videoPlayer && !this.videoLoaded) {
			const video = this.videoPlayer.nativeElement;
			video.muted = true;

			const playPromise = video.play();

			if (playPromise !== undefined) {
				playPromise
					.then((_) => {
						// Automatic playback started!
						this.videoLoaded = true;
					})
					.catch((error) => {
						// Auto-play was prevented
						console.error("Error attempting to play video:", error);
					});
			}
		}
	}
}
