import { Component, ElementRef, Inject, OnInit, Renderer2, ViewChild, ViewEncapsulation } from "@angular/core";
import { RestApiService } from "src/app/core/services/rest-api.service";
import { LasService } from "./core/services/las.service";
import { Subject, takeUntil } from "rxjs";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { environment } from "src/environments/environment";

import { DOCUMENT } from "@angular/common";

import { FingerprintService } from "src/app/core/services/finger-print.service";
import { RoutingOrderService } from "src/app/core/services/routing-order.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { AppStatusService } from "src/app/core/services/standalone.listnet.service";

@Component({
	selector: "app-las",
	templateUrl: "./las.component.html",
	styleUrls: [
		"../../../../src/assets/las/scss/las.scss",
		"../../../../node_modules/slick-carousel/slick/slick.scss",
		"../../../../node_modules/slick-carousel/slick/slick-theme.scss",
		"../../../../node_modules/bootstrap-icons/font/bootstrap-icons.css",
	],
	encapsulation: ViewEncapsulation.None,
})
export class LasComponent implements OnInit {
	unsubscriber: Subject<any> = new Subject<any>();
	backendURL = environment.backendURL;
	uuid: string;
	newUuid: string;

	campaignFontFamily: string;
	campaignColor: string;
	isStandalone = false;
	isOnline = false;

	ctaTextColor: string;
	ctaBgColor: string;
	ctaBorderColor: string;
	compagnFont: string;
	slug: string;

	lasId: number;
	lasSlug: any;
	lasSigningMode: string;
	verificationMethod: string;
	yotiLogVerfication: any;

	backendSteps: any;
	backendSlug: any;
	routeOrder: any;

	hostessInfo: any;

	constructor(
		private lasService: LasService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private restApiService: RestApiService,
		private fingerprintService: FingerprintService,
		@Inject(DOCUMENT) private document: Document,
		private renderer: Renderer2,
		private routingOrderService: RoutingOrderService,
		private ngxLoader: NgxUiLoaderService,
		private appStatusService: AppStatusService
	) {}

	// --------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// --------------------------------------------------------------------------------------------
	async ngOnInit() {
		// font family loader.
		this.fontFamilyLoader();

		// Get the device uuid
		this.uuid = await this.fingerprintService.getDeviceId();

		// Generate random device uuid
		this.newUuid = await this.fingerprintService.getRandomDeviceId();

		// Log verfication initialize
		this.initializeLogVerfication();

		// Initial the routing order service
		this.routingOrderService.callThisService();

		// check stand alone mode from service
		this.appStatusService.isStandalone$.subscribe((isStandalone) => {
			this.isStandalone = isStandalone;
			console.log("Standalone mode:", this.isStandalone);
			this.checkRefresh();
		});

		// update online offline status
		this.appStatusService.isOnline$.subscribe((isOnline) => {
			this.isOnline = isOnline;
			console.log("Online status:", this.isOnline);
			this.checkRefresh();
		});

		this.activatedRoute.params.pipe(takeUntil(this.unsubscriber)).subscribe((params: Params) => {
			if (params["campaign-slug"] !== undefined) {
				this.restApiService
					.get(`campaigns/${params["campaign-slug"]}`)
					.pipe(takeUntil(this.unsubscriber))
					.subscribe((response) => {
						this.lasId = response.data?.id;
						this.lasSlug = response.data?.slug;
						this.lasSigningMode = response.data?.signingMode;
						this.campaignFontFamily = response.data?.theme.fontFamily;

						const currentSlug = JSON.parse(localStorage.getItem("slug"));
						if (currentSlug !== null) {
							if (currentSlug !== this.lasSlug) {
								// Change the uuid if the campaign slug is different
								this.uuid = this.newUuid;

								// Check if the campaign signing mode
								if (this.lasSigningMode === "public") {
									// Clear the entire localStorage except for the 'device_fingerprint' key
									this.clearLocalStorageExcept("device_fingerprint");

									localStorage.setItem("slug", JSON.stringify(this.lasSlug));
								} else if (this.lasSigningMode === "hostess") {
									// Clear the entire localStorage except for the 'hostessInfo' and 'device_fingerprint' keys
									this.clearLocalStorageExcept("device_fingerprint", "hostessInfo");

									localStorage.setItem("slug", JSON.stringify(this.lasSlug));
								}
							}
						} else {
							localStorage.setItem("slug", JSON.stringify(this.lasSlug));
						}

						this.verificationMethod = response.data.ageVerificationMethod;

						localStorage.setItem("verificationMethod", this.verificationMethod);

						// Check if the verifaction method is yoti
						if (this.lasSlug && this.verificationMethod === "yoti") {
							// Check yoti age verfication
							this.activatedRoute.queryParamMap.subscribe((params) => {
								// Get verification result & token from the url
								const yotiVerification = params.get("yotiVerification");

								// Check if verfication success
								if (yotiVerification === "success") {
									// Update the age verifaction endpoint
									this.restApiService
										.put("/lases/set-age-verified", this.uuid, {})
										.pipe(takeUntil(this.unsubscriber))
										.subscribe((response) => {
											console.log(response);

											// Log step yoti age verification
											this.logStep(this.lasId, "Yoti age verification");
											this.updateLogVerfication("Yoti age verification", "true");

											localStorage.setItem("isAgeVerified", "true");
										});
								} else {
									this.restApiService
										.get(`/lases/${this.uuid}`)
										.pipe(takeUntil(this.unsubscriber))
										.subscribe((response) => {
											const { isAgeVerified } = response.data;

											if (isAgeVerified) {
												localStorage.setItem("isAgeVerified", "true");
											} else {
												localStorage.setItem("isAgeVerified", "false");
											}
										});
								}
							});
						}

						// Set the campaign cta theme colors
						this.ctaTextColor = response.data?.theme.cta.textColor;
						this.ctaBgColor = response.data?.theme.cta.bgColor;
						this.ctaBorderColor = response.data?.theme.cta.borderColor;

						// Set the campaign theme color and call the updateColor function
						this.campaignColor = response.data.theme.color;

						// Set the campaign slug
						this.slug = response.data.slug;

						// Set the campaign font family
						this.compagnFont = this.campaignFontFamily;

						this.updateColor();

						// Set direction based on language
						this.setDirection(response.data.lang); // response.data.lang

						this.lasService.campaignSubject.next({ campaign: response.data });

						// Redirect to the status component base on the campaign status
						if (!this.isDateInRange(response.data?.startDate, response.data?.endDate) || !response.data?.isActif) {
							this.router.navigate([`las/${params["campaign-slug"]}/status`]);
							return;
						} else if (this.router.url.includes("/status")) {
							this.router.navigate([`las/${params["campaign-slug"]}/policy`]);
						}
					});
			}

			this.hostessInfo = localStorage.getItem("hostessInfo") ? JSON.parse(localStorage.getItem("hostessInfo")) : null;
			if (this.hostessInfo) {
				// Dispatch lasSubject for application wide uses
				this.restApiService
					.get(`/lases/${this.uuid}`)
					.pipe(takeUntil(this.unsubscriber))
					.subscribe((response) => {
						this.lasService.lasSubject.next(response.data);
					});
			} else {
				// Check/register UUID in backend API, if not exist and get Las data from backend API
				this.lasService
					.checkAndRegisterUUID(this.uuid)
					.pipe(takeUntil(this.unsubscriber))
					.subscribe({
						next: (response) => {
							// Dispatch lasSubject for application wide uses
							this.lasService.lasSubject.next(response.data);
							console.log("las loged in");
						},
						error: (error) => {
							console.error("Error checking/registering UUID:", error);
						},
					});
			}
		});
	}

	// get data by id and winning product and status = to offline
	postStoredSpindOffline() {
		// Start the loading effect
		this.ngxLoader.start();

		// Get the stored spins offline from the localStorage & Call winProduct endpoint foreach product in the stored spins from localStorage
		const storedSpinsOffline = JSON.parse(localStorage.getItem("storedSpinsOffline"));
		storedSpinsOffline.forEach((winningProduct: any, index: any) => {
			this.restApiService
				.get(`campaigns/${winningProduct.slug}/winProduct?lasId=${this.lasId}&winningId=${winningProduct.id}&state=offline`)
				.pipe(takeUntil(this.unsubscriber))
				.subscribe({
					next: (res) => {
						console.log(`Spin ${index + 1} successful. Product id:`, res);
						// Remove the spin from local storage after processing
						if (index === storedSpinsOffline.length - 1) {
							localStorage.removeItem("storedSpinsOffline");
						}

						this.ngxLoader.stop();
					},
					error: (error) => {
						console.log(`Error during spin ${index + 1} operation:`, error);

						if (error.status === 404) {
							console.log(`Product not found (404):`, error.message);
						}

						this.ngxLoader.stop();
					},
				});
		});
	}

	checkRefresh() {
		// Retrieve 'storedSpinsOffline' from localStorage
		const storedSpinsOffline = JSON.parse(localStorage.getItem("storedSpinsOffline"));

		// condition to check if standalone and is online to poste  stored spinned value
		if (this.isStandalone && this.isOnline && storedSpinsOffline && storedSpinsOffline.length > 0) {
			this.postStoredSpindOffline();
		}
	}

	ngAfterViewInit(): void {}

	ngOnDestroy(): void {
		this.unsubscriber.next(null);
		this.unsubscriber.complete();
	}

	// --------------------------------------------------------------------------------------------
	// @ Functions
	// --------------------------------------------------------------------------------------------
	// Load specific font family.
	fontFamilyLoader() {
		const font = new FontFace("Flood", "url(../../../../assets/las/fonts/flood.otf)");

		// Load the font family
		font.load()
			.then((loadedFont) => {
				// Type assertion to any to bypass TypeScript checking
				(document.fonts as any).add(loadedFont);
				console.log("font family loaded", loadedFont);
			})
			.catch((error) => {
				console.error("Font loading failed:", error);
			});
	}

	setFullHeight() {
		const vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty("--vh", `${vh}px`);
	}

	// Direction functionality
	setDirection(lang: string) {
		const isRTL = lang === "ar";
		this.document.documentElement.dir = isRTL ? "rtl" : "ltr";
		this.document.body.dir = isRTL ? "rtl" : "ltr";

		// Add a class to the body for additional styling
		if (isRTL) {
			this.document.body.classList.add("rtl");
			this.document.body.classList.remove("ltr");
		} else {
			this.document.body.classList.add("ltr");
			this.document.body.classList.remove("rtl");
		}
	}

	// Set colors in variables dynamically
	updateColor() {
		this.renderer.setProperty(
			this.document.documentElement,
			"style",
			`
--campaign-color: ${this.campaignColor};
--cta-textColor: ${this.ctaTextColor};
--cta-BgColor: ${this.ctaBgColor};
--cta-BorderColor: ${this.ctaBorderColor};
--campaign-color: ${this.campaignColor};
--cta-textColor: ${this.ctaTextColor};
--cta-BgColor: ${this.ctaBgColor};
--campaign-font: ${this.compagnFont};
--friend-names-font: "Flood";
            `
		);
	}

	// Check if date is between the start and end date
	isDateInRange(startDate, endDate) {
		const currentDate = new Date();
		const start = new Date(startDate);
		const end = new Date(endDate);

		// Set time to midnight for accurate date comparison
		currentDate.setHours(0, 0, 0, 0);
		start.setHours(0, 0, 0, 0);
		end.setHours(0, 0, 0, 0);

		return currentDate >= start && currentDate <= end;
	}

	clearLocalStorageExcept(...keysToKeep: string[]) {
		// Store the values we want to keep
		const valuesToKeep = keysToKeep.reduce(
			(acc, key) => {
				const value = localStorage.getItem(key);
				if (value !== null) {
					acc[key] = value;
				}
				return acc;
			},
			{} as Record<string, string>
		);

		// Clear everything
		localStorage.clear();

		// Restore the values we want to keep
		Object.entries(valuesToKeep).forEach(([key, value]) => {
			localStorage.setItem(key, value);
		});
	}

	// Log step
	logStep(lasId: number, stepLabel: string) {
		this.lasService
			.logStep(lasId, stepLabel)
			.pipe(takeUntil(this.unsubscriber))
			.subscribe({
				next: (response) => {
					console.log("Journey yoti created:", response);
				},
				error: (error) => {
					console.error("Error creating journey:", error);
				},
			});
	}

	// Initialize LogVerfication & Update the component log
	private initializeLogVerfication() {
		const storedState = localStorage.getItem("logVerfication");
		this.yotiLogVerfication = storedState ? JSON.parse(storedState) : {};
	}
	private updateLogVerfication(key: string, value: any) {
		this.yotiLogVerfication[key] = value;
		localStorage.setItem("logVerfication", JSON.stringify(this.yotiLogVerfication));
	}
}
