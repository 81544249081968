<!-- Main section -->
<main>
	<!-- Header section -->

	<!-- Content section -->
	<section class="content-container" id="content-container">
		<div class="main-content">
			@if (isCampaignActif) {
				<p class="campaign-status">The current campaign is not active.</p>
			} @else if (isCampaignUpToDate) {
				<p class="campaign-status">The current campaign is not up to date.</p>
			}
		</div>
	</section>

	<!-- Footer section -->
</main>
