<header id="page-topbar" data-scroll-header (window:scroll)="windowScroll()">
	<div class="layout-width">
		<div class="navbar-header shadow-sm_">
			<div class="d-flex">
				<!-- LOGO -->
				<div class="navbar-brand-box horizontal-logo">
					<a href="javascript:void(0);" class="logo logo-dark">
						<span class="logo-sm">
							<img src="assets/admin/images/logo.png" alt="" height="22" />
						</span>
						<span class="logo-lg">
							<img src="assets/admin/images/logo.png" alt="" height="17" />
						</span>
					</a>

					<a href="javascript:void(0);" class="logo logo-light">
						<span class="logo-sm">
							<img src="assets/admin/images/logo.png" alt="" height="22" />
						</span>
						<span class="logo-lg">
							<img src="assets/admin/images/logo.png" alt="" height="17" />
						</span>
					</a>
				</div>

				<button type="button" class="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger shadow-none" id="topnav-hamburger-icon" (click)="toggleMobileMenu($event)">
					<span class="hamburger-icon">
						<span></span>
						<span></span>
						<span></span>
					</span>
				</button>

				<!-- App Search-->
				<form class="app-search d-none d-md-block">
					<!-- <div class="position-relative">
						<input type="text" class="form-control" placeholder="بحث عام ..." autocomplete="off" id="search-options" value="" (keyup)="Search()" />
						<span class="mdi mdi-magnify search-widget-icon"></span>
						<span class="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none" id="search-close-options" (click)="closeBtn()"></span>
					</div> -->
					<!-- <div class="dropdown-menu dropdown-menu-lg" id="search-dropdown">
						<ngx-simplebar style="max-height: 320px">
							<div class="dropdown-header">
								<h6 class="text-overflow text-muted mb-0 text-uppercase">Recent Searches</h6>
							</div>

							<div class="dropdown-item bg-transparent text-wrap">
								<a href="javascript:void(0);" class="btn btn-soft-secondary btn-sm rounded-pill me-1">how to setup <i class="mdi mdi-magnify ms-1"></i></a>
								<a href="javascript:void(0);" class="btn btn-soft-secondary btn-sm rounded-pill">buttons <i class="mdi mdi-magnify ms-1"></i></a>
							</div>

							<div class="dropdown-header mt-2">
								<h6 class="text-overflow text-muted mb-1 text-uppercase">Pages</h6>
							</div>

							<a href="javascript:void(0);" class="dropdown-item notify-item">
								<i class="ri-bubble-chart-line align-middle fs-18 text-muted me-2"></i>
								<span>Analytics Dashboard</span>
							</a>

							<a href="javascript:void(0);" class="dropdown-item notify-item">
								<i class="ri-lifebuoy-line align-middle fs-18 text-muted me-2"></i>
								<span>Help Center</span>
							</a>

							<a href="javascript:void(0);" class="dropdown-item notify-item">
								<i class="ri-user-settings-line align-middle fs-18 text-muted me-2"></i>
								<span>My account settings</span>
							</a>

							<div class="dropdown-header mt-2">
								<h6 class="text-overflow text-muted mb-2 text-uppercase">Members</h6>
							</div>

							<div class="notification-list">
								<a href="javascript:void(0);" class="dropdown-item notify-item py-2">
									<div class="d-flex">
										<img src="assets/admin/images/users/avatar-2.jpg" class="me-3 rounded-circle avatar-xs" alt="user-pic" />
										<div class="flex-grow-1">
											<h6 class="m-0">Angela Bernier</h6>
											<span class="fs-11 mb-0 text-muted">Manager</span>
										</div>
									</div>
								</a>

								<a href="javascript:void(0);" class="dropdown-item notify-item py-2">
									<div class="d-flex">
										<img src="assets/admin/images/users/avatar-3.jpg" class="me-3 rounded-circle avatar-xs" alt="user-pic" />
										<div class="flex-grow-1">
											<h6 class="m-0">David Grasso</h6>
											<span class="fs-11 mb-0 text-muted">Web Designer</span>
										</div>
									</div>
								</a>

								<a href="javascript:void(0);" class="dropdown-item notify-item py-2">
									<div class="d-flex">
										<img src="assets/admin/images/users/avatar-5.jpg" class="me-3 rounded-circle avatar-xs" alt="user-pic" />
										<div class="flex-grow-1">
											<h6 class="m-0">Mike Bunch</h6>
											<span class="fs-11 mb-0 text-muted">React Developer</span>
										</div>
									</div>
								</a>
							</div>
						</ngx-simplebar>

						<div class="text-center pt-3 pb-1">
							<a href="pages/search-results" class="btn btn-primary btn-sm">View All Results <i class="ri-arrow-right-line ms-1"></i></a>
						</div>
					</div> -->
				</form>
			</div>

			<div class="d-flex align-items-center">
				<div class="dropdown d-md-none topbar-head-dropdown header-item" ngbDropdown>
					<button
						type="button"
						class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle shadow-none"
						id="page-header-search-dropdown"
						data-bs-toggle="dropdown"
						aria-haspopup="true"
						aria-expanded="false"
						ngbDropdownToggle
					>
						<i class="bx bx-search fs-22"></i>
					</button>
					<div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" aria-labelledby="page-header-search-dropdown" ngbDropdownMenu>
						<form class="p-3">
							<div class="form-group m-0">
								<div class="input-group">
									<input type="text" class="form-control" placeholder="Search ..." aria-label="Recipient's username" />
									<button class="btn btn-primary" type="submit"><i class="mdi mdi-magnify"></i></button>
								</div>
							</div>
						</form>
					</div>
				</div>

				<div ngbDropdown [autoClose]="false" class="dropdown topbar-head-dropdown ms-4 header-item">
					<button
						type="button"
						ngbDropdownToggle
						class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle shadow-none"
						id="page-header-notifications-dropdown"
						data-bs-toggle="dropdown"
						aria-haspopup="true"
						aria-expanded="false"
						[routerLink]="['/platform/tasks']"
					>
						<i class="bx bx-bell fs-22"></i>
						<!-- <span class="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">3<span class="visually-hidden">unread messages</span></span> -->
					</button>
					<!-- <div ngbDropdownMenu class="dropdown-menu dropdown-menu-lg dropdown-menu-start p-0" aria-labelledby="page-header-notifications-dropdown">
						<div class="dropdown-head bg-primary rounded-top">
							<div class="p-3">
								<div class="row align-items-center">
									<div class="col">
										<h6 class="m-0 fs-16 fw-semibold text-white">الإشعارات</h6>
									</div>
								</div>
							</div>
							<div class="px-2 pt-2">
								<ul ngbNav #nav="ngbNav" [activeId]="1" class="nav nav-tabs dropdown-tabs nav-tabs-custom" id="notificationItemsTab">
									<li [ngbNavItem]="1">
										<a ngbNavLink> All ({{ allnotifications?.length }}) </a>
										<ng-template ngbNavContent>
											<div class="tab-pane fade show active py-2 ps-2" id="all-noti-tab" role="tabpanel">
												<ngx-simplebar style="max-height: 300px">
													<div class="pe-2">
														@for (item of allnotifications; track $index) {
															<div class="text-reset notification-item d-block dropdown-item position-relative">
																<div class="d-flex">
																	@if (item.img) {
																		<img src="{{ item.img }}" class="me-3 rounded-circle avatar-xs" alt="user-pic" />

																		<div class="flex-grow-1">
																			<a href="javascript:void(0);" class="stretched-link">
																				<h6 class="mt-0 mb-1 fs-13 fw-semibold">
																					{{ item.title }}
																				</h6>
																			</a>
																			<div class="fs-13 text-muted">
																				<p class="mb-1">{{ item.desc }}</p>
																			</div>
																			<p class="mb-0 fs-11 fw-medium text-uppercase text-muted">
																				<span><i class="mdi mdi-clock-outline"></i> {{ item.time }}</span>
																			</p>
																		</div>
																	} @else {
																		<div class="avatar-xs me-3">
																			<span class="avatar-title bg-info-subtle text-info rounded-circle fs-16">
																				<i class="bx {{ item.icon }}"></i>
																			</span>
																		</div>
																		<div class="flex-grow-1">
																			<a href="javascript:void(0);" class="stretched-link">
																				<h6 class="mt-0 mb-2 lh-base">{{ item.desc }}</h6>
																			</a>
																			<p class="mb-0 fs-11 fw-medium text-uppercase text-muted">
																				<span><i class="mdi mdi-clock-outline"></i>{{ item.time }}</span>
																			</p>
																		</div>
																	}
																	<div class="px-2 fs-15">
																		<input
																			class="form-check-input"
																			type="checkbox"
																			[(ngModel)]="item.state"
																			(change)="onCheckboxChange($event, '1')"
																			value="{{ item.id }}"
																		/>
																	</div>
																</div>
															</div>
														} @empty {
															<div
																[ngClass]="allnotifications?.length != 0 ? 'd-none' : ''"
																class="tab-pane p-4"
																id="alerts-tab"
																role="tabpanel"
																aria-labelledby="alerts-tab"
															>
																<div class="w-25 w-sm-50 pt-3 mx-auto">
																	<img src="assets/admin/images/svg/bell.svg" class="img-fluid" alt="user-pic" />
																</div>
																<div class="text-center pb-5 mt-2">
																	<h6 class="fs-18 fw-semibold lh-base">لا يوجد إشعارات في هاته اللحظة</h6>
																</div>
															</div>
														}
														@if (allnotifications?.length > 0) {
															<div class="my-3 text-center">
																<button type="button" class="btn btn-soft-success waves-effect waves-light">
																	View All Notifications <i class="ri-arrow-right-line align-middle"></i>
																</button>
															</div>
														}
													</div>
												</ngx-simplebar>
											</div>
										</ng-template>
									</li>
									<li [ngbNavItem]="2">
										<a ngbNavLink> Alerts </a>
										<ng-template ngbNavContent>
											<div class="tab-pane p-4" id="alerts-tab" role="tabpanel" aria-labelledby="alerts-tab">
												<div class="w-25 w-sm-50 pt-3 mx-auto">
													<img src="assets/admin/images/svg/bell.svg" class="img-fluid" alt="user-pic" />
												</div>
												<div class="text-center pb-5 mt-2">
													<h6 class="fs-18 fw-semibold lh-base">Hey! You have no any notifications</h6>
												</div>
											</div>
										</ng-template>
									</li>
								</ul>
							</div>
						</div>
						<div class="tab-content" id="notificationItemsTabContent">
							<div class="tab-content text-muted">
								<div [ngbNavOutlet]="nav"></div>
							</div>
						</div>
						<div class="notification-actions" id="notification-actions">
							<div class="d-flex text-muted justify-content-center">
								Select
								<div id="select-content" class="text-body fw-semibold px-1">
									{{ checkedValGet.length }}
								</div>
								Result
								<button
									type="button"
									class="btn btn-link link-danger p-0 ms-3"
									data-bs-toggle="modal"
									(click)="openModal(removenotification)"
									data-bs-target="#removeNotificationModal"
								>
									Remove
								</button>
							</div>
						</div>
					</div> -->
				</div>

				<div class="ms-1 header-item d-none d-sm-flex">
					<button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle shadow-none light-dark-mode">
						<i class="bx bx-moon fs-22" (click)="changeMode('dark')"></i>
						<i class="bx bx-sun fs-22" (click)="changeMode('light')"></i>
					</button>
				</div>

				<div class="ms-1 header-item d-none d-sm-flex">
					<button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle shadow-none" data-toggle="fullscreen" (click)="fullscreen()">
						<i class="bx bx-fullscreen fs-22"></i>
					</button>
				</div>

				<div class="dropdown ms-sm-3 header-item topbar-user" ngbDropdown>
					<button type="button" class="btn shadow-none" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
						<span class="d-flex align-items-center">
							<!-- <img
								class="rounded-circle header-profile-user"
								src="{{ backendURL + authData.avatar?.url }}"
								alt="Header Avatar"
								onerror="this.src='assets/admin/images/users/avatar.jpg'"
							/> -->
							<img class="rounded-circle header-profile-user" src="assets/admin/images/users/avatar-9.jpg" alt="Header Avatar" />
							<span class="text-start ms-xl-2">
								<span class="d-none d-xl-inline-block ms-1 fw-medium user-name-text"> John Doe </span>
								<span class="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">
									{{ authData?.role == "admin" ? "Admin" : "Admin" }}
								</span>
							</span>
						</span>
					</button>
					<div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
						<!-- <h6 class="dropdown-header fs-14">مرحبا علي</h6> -->
						<a class="dropdown-item" routerLink="/pages/profile"> <i class="las la-user-circle text-muted fs-18 align-middle me-2"></i> <span class="align-middle">Profile</span> </a>
						<a class="dropdown-item" routerLink="/pages/profile">
							<i class="las la-cog text-muted fs-18 align-middle me-2"></i>
							<span class="align-middle">Settings</span>
						</a>
						<div class="dropdown-divider"></div>
						<a class="dropdown-item" href="javascript: void(0);" (click)="logout()">
							<i class="las la-sign-out-alt text-muted fs-18 align-middle me-2"></i> <span class="align-middle" data-key="t-logout">Sign out</span>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</header>

<!-- removeNotificationModal -->
<ng-template #removenotification let-modal>
	<div class="modal-content">
		<div class="modal-header">
			<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="NotificationModalbtn-close" (click)="modal.dismiss('Cross click')"></button>
		</div>
		<div class="modal-body">
			<div class="mt-2 text-center">
				<lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop" colors="primary:#f7b84b,secondary:#f06548" style="width: 100px; height: 100px"></lord-icon>
				<div class="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
					<h4>Are you sure ?</h4>
					<p class="text-muted mx-4 mb-0">Are you sure you want to remove this Notification ?</p>
				</div>
			</div>
			<div class="d-flex gap-2 justify-content-center mt-4 mb-2">
				<button type="button" class="btn w-sm btn-light" data-bs-dismiss="modal" (click)="modal.dismiss('Cross click')">Close</button>
				<button type="button" class="btn w-sm btn-danger" id="delete-notification" (click)="notificationDelete()">Yes, Delete It!</button>
			</div>
		</div>
	</div>
	<!-- /.modal-content -->
</ng-template>
