import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from "@angular/router";
import { Observable } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class LasGuard {
	constructor(private router: Router) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		const verificationMethod = this.getVerificationMethod();

		if (verificationMethod === "yoti") {
			// Check for yotiVerification parameter
			const yotiStatus = route.queryParams["yotiVerification"];

			// If yotiVerification is success, remove isAgeVerified and allow access
			if (yotiStatus === "success") {
				localStorage.removeItem("isAgeVerified");
				return true;
			}

			// If no yoti parameter, proceed with age verification check
			const isAgeVerified = this.getUserAgeVerificationStatus();

			if (isAgeVerified === "false") {
				const slug = JSON.parse(localStorage.getItem("slug"));

				return this.router.createUrlTree([`las/${slug}/policy`]);
			} else {
				// Age is not verified, stay on the current route
				return true;
			}
		}

		return true;
	}

	private getVerificationMethod() {
		return localStorage.getItem("verificationMethod");
	}

	private getUserAgeVerificationStatus(): string | null {
		return localStorage.getItem("isAgeVerified");
	}
}
