import { Component, OnInit, Output, ViewEncapsulation, EventEmitter, ViewChild, ElementRef } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subject, takeUntil } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { LasService } from "../core/services/las.service";
import { environment } from "src/environments/environment";
import { RoutingOrderService } from "src/app/core/services/routing-order.service";
import { loginValidator } from "../core/directives/login.directive";
import { passwordValidator } from "../core/directives/password.directive";
import { RestApiService } from "src/app/core/services/rest-api.service";
import { UrlService } from "../core/services/url.service";

@Component({
	selector: "app-hostess-auth",
	templateUrl: "./hostess-auth.component.html",
	styleUrls: ["hostess-auth.component.scss", "../../../../../src/assets/las/scss/las.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class HostessAuthComponent implements OnInit {
	unsubscriber: Subject<any> = new Subject<any>();
	backendURL = environment.backendURL;
	ngStyle: any;
	form: FormGroup;
	tutorialData: any;
	footerBtnText: string = "Commencer";
	footerText: string;
	campaignHeader: any;
	tuturialHeaderOpt: boolean;
	tuturialFooterOpt: boolean;

	// LAS Log
	lasData: any;
	stepName: string;
	hostessAuthLogVerfication: any;

	// CTA Theme
	colorCTA: string = "#fff";
	bgColorCTA: string = "#D63231";
	borderCTA: string = "none";

	backgroundImageUrl: string;

	// Data Collection
	loginInput: string;
	passwordInput: any;

	// Hostess Data
	isHostessLogin: boolean;
	inputWarningMessage: string = "";
	hostessCampaigns: any;

	isStandalone: boolean = false;

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private lasService: LasService,
		private routingOrderService: RoutingOrderService,
		private restApiService: RestApiService,
		private fb: FormBuilder,
		private urlService: UrlService
	) {}
	// --------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// --------------------------------------------------------------------------------------------
	ngOnInit(): void {
		this.initializeLogVerfication();
		this.checkStandaloneMode();

		this.form = this.fb.group({
			// identifier: ["", [Validators.required, loginValidator()]],
			// password: ["", [Validators.required, passwordValidator()]],

			identifier: ["", [Validators.required]],
			password: ["", [Validators.required]],
		});

		this.lasService.campaignSubject.pipe(takeUntil(this.unsubscriber)).subscribe((data: any) => {
			if (data != null) {
				this.campaignHeader = data.campaign.theme.header;
				this.footerText = data.campaign.theme.footer;

				// this.tutorialData = data.campaign.steps.filter((e) => e.__component === "screen.tutorial")[0];
				// this.tuturialHeaderOpt = this.tutorialData.isHeaderEnabled;
				// this.tuturialFooterOpt = this.tutorialData.isFooterEnabled;

				// Log step
				// const stepLebelFormated = this.removeSpacesBetweenWords(this.tutorialData.stepLabel);
				// if (!this.hostessAuthLogVerfication[stepLebelFormated]) {
				// 	this.stepName = this.tutorialData.stepLabel;
				// 	this.lasService.lasSubject.pipe(takeUntil(this.unsubscriber)).subscribe((data) => {
				// 		if (data != null) {
				// 			this.lasData = data;
				// 			this.logStep(this.lasData.id, this.stepName);
				// 		}
				// 	});

				// 	this.updateLogVerfication(this.removeSpacesBetweenWords(this.stepName), "true");
				// }

				// this.footerBtnText = this.tutorialData.ctaText;

				// Set theme --------------------
				// const theme = data.campaign.theme;
				// let bgImageUrl = this.backendURL + theme.bgImg.url;

				// this.ngStyle = {
				// 	color: theme.textColor,
				// };

				// if (this.tutorialData.bgImg != null) {
				// 	bgImageUrl = this.backendURL + this.tutorialData.bgImg.url;
				// }
				// this.backgroundImageUrl = bgImageUrl;
			}
		});
	}

	ngAfterViewInit(): void {
		const inputElementLogin = document.getElementById("loginInputId") as HTMLInputElement;
		if (inputElementLogin) {
			inputElementLogin.addEventListener("input", (event: Event) => {
				const input = event.target as HTMLInputElement;
				const value = input.value;

				const newValue = value.replace(/[^a-zA-Z0-9@._-]/g, "");

				if (value !== newValue) {
					input.value = newValue;
				}
			});
		}

		const inputElementPassword = document.getElementById("passwordInputId") as HTMLInputElement;
		if (inputElementPassword) {
			inputElementPassword.addEventListener("input", (event: Event) => {
				const input = event.target as HTMLInputElement;
				const value = input.value;

				const newValue = value.replace(/[^a-zA-Z0-9@._\-!#$%^&*()]/g, "");

				if (value !== newValue) {
					input.value = newValue;
				}
			});
		}
	}

	ngOnDestroy(): void {
		this.unsubscriber.next(null);
		this.unsubscriber.complete();
	}
	// --------------------------------------------------------------------------------------------
	// @ Events
	// --------------------------------------------------------------------------------------------
	onFooterBtnClick() {
		if (this.form.valid) {
			const formValue = this.form.value;
			formValue.identifier = formValue.identifier.trim();
			formValue.password = formValue.password.trim();

			// Process the form data
			console.log(formValue);
			this.restApiService
				.post("auth/local", formValue)
				.pipe(takeUntil(this.unsubscriber))
				.subscribe({
					next: (response) => {
						const { role } = response.user;

						if (role.type === "hostess") {
							// Save hostess info in the localStorage
							localStorage.setItem("hostessInfo", JSON.stringify(response));
							this.hostessCampaigns = response.user.campaigns;

							// Hostess is login successfuly
							this.isHostessLogin = true;
						} else {
							// Hostess is not login successfulys
							this.isHostessLogin = false;
						}
					},
					error: (error) => {
						this.inputWarningMessage = "Invalid username or password";
					},
				});
		} else {
			console.log("Form is invalid", this.form.get("identifier")?.errors);
			console.log("Form is invalid", this.form.get("password")?.errors);
		}
	}

	setStartUrl(url: string) {
		console.log("PWA");
		this.urlService.setUrl(url);
	}

	setStartUrlWeb(url: string) {
		console.log("WEB");
		this.router.navigate([url]);
	}
	// --------------------------------------------------------------------------------------------
	// @ Function
	// --------------------------------------------------------------------------------------------

	// Checks if the app is running in standalone mode
	private checkStandaloneMode(): void {
		if (window.matchMedia("(display-mode: standalone)").matches || (window.navigator as any).standalone) {
			this.isStandalone = true;
		}
	}

	logStep(lasId: number, stepLabel: string) {
		this.lasService
			.logStep(lasId, stepLabel)
			.pipe(takeUntil(this.unsubscriber))
			.subscribe({
				next: (response) => {
					console.log("Journey hostess auth created:", response);
				},
				error: (error) => {
					console.error("Error creating journey:", error);
				},
			});
	}

	// Initialize LogVerfication & Update the component log
	private initializeLogVerfication() {
		const storedState = localStorage.getItem("logVerfication");
		this.hostessAuthLogVerfication = storedState ? JSON.parse(storedState) : {};
	}

	private updateLogVerfication(key: string, value: any) {
		this.hostessAuthLogVerfication[key] = value;
		localStorage.setItem("logVerfication", JSON.stringify(this.hostessAuthLogVerfication));
	}

	// Remove spaces from the text
	removeSpacesBetweenWords(text: any) {
		return text.replace(/(\S)\s+(\S)/g, "$1$2");
	}
}
