import { Injectable } from "@angular/core";
import { Router, UrlTree } from "@angular/router";

@Injectable({
	providedIn: "root",
})
export class WheelGuard {
	constructor(private router: Router) {}

	canActivate(): boolean | UrlTree {
		const routeOrder = JSON.parse(localStorage.getItem("routeOrder") || "[]");
		const slug = JSON.parse(localStorage.getItem("slug"));
		const hasWon = JSON.parse(localStorage.getItem("hasWon"));

		if (routeOrder.length > 0 && hasWon) {
			// Redirect to the first route in the order

			localStorage.setItem("hasWon", "false");
			return this.router.createUrlTree([`/las/${slug}/${routeOrder[0]}`]);
		}

		// Allow access to wheel if no routes in order
		return true;
	}
}
