import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject, takeUntil } from "rxjs";
import { environment } from "src/environments/environment";
import { RestApiService } from "src/app/core/services/rest-api.service";
import { LasService } from "../../core/services/las.service";
import { RoutingOrderService } from "src/app/core/services/routing-order.service";
import { ThemeService } from "../../core/services/theme.service";

@Component({
	selector: "app-result",
	templateUrl: "./result.component.html",
	styleUrl: "./result.component.scss",
	encapsulation: ViewEncapsulation.None,
})
export class ResultComponent implements OnInit {
	unsubscriber: Subject<any> = new Subject<any>();
	backendURL = environment.backendURL;
	ngStyle: any;

	isOnline = true;
	isStandalone = false;

	uuid: string;

	campaignHeader: any;
	footerBtnText: string;
	footerText: string;

	// Component functionality
	selectedRegion: string = "";
	countClick: number = 0;
	isCopied: boolean = false;

	// Wheel data
	productData: any;
	userWheelStatus: "lose" | "win" = "win";
	userWheelProductType: "physical" | "voucher" | "" = "";
	userWheelProductCode: string = "";
	userWheelProductImg: string = "";
	userWheelProductName: string = "";
	wheelData: any;

	// Result data
	isInputHasValue: boolean = false;
	resultMessage: string;
	resultHeaderOpt: boolean;
	resultFooterOpt: boolean;

	colorCTA: string = "#fff";
	bgColorCTA: string = "teal";
	borderCTA: string = "none";

	backgroundImageUrl: string;

	lang: string;

	// Routing config
	inputSelectValues = [];
	isAnyNextRoute: any;

	// Result texts
	winMessage: string;
	voucherWinMessage: string;
	physicalWinMessage: string;
	lostMessage: any;

	// Global CTA
	ctaNextText: string;
	ctaRetryText: string;

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private restApiService: RestApiService,
		private lasService: LasService,
		private routingOrderService: RoutingOrderService,
		private themeService: ThemeService
	) {}

	// --------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// --------------------------------------------------------------------------------------------
	ngOnInit(): void {
		this.uuid = localStorage.getItem("device_fingerprint");

		// Check if is standalone mode
		this.checkStandaloneMode();

		// Update online status mode
		this.updateOnlineStatus();

		this.lasService.campaignSubject.pipe(takeUntil(this.unsubscriber)).subscribe((data: any) => {
			if (data != null) {
				this.campaignHeader = data.campaign.theme.header;
				this.footerText = data.campaign.theme.footer;

				this.ctaNextText = data.campaign.theme.cta?.ctaNextText;
				this.ctaRetryText = data.campaign.theme.cta?.ctaRetryText;

				this.wheelData = data.campaign.steps.filter((e) => e.__component === "wheel.wheel")[0];

				this.winMessage = this.wheelData.winMessage;
				this.voucherWinMessage = this.wheelData.voucherWinMessage;
				this.physicalWinMessage = this.wheelData.physicalWinMessage;
				this.lostMessage = this.wheelData.lostMessage;

				this.resultHeaderOpt = this.wheelData.isHeaderEnabled;
				this.resultFooterOpt = this.wheelData.isFooterEnabled;

				// Set theme --------------------
				const theme = data.campaign.theme;
				const { ngStyle, backgroundImageUrl } = this.themeService.getThemeStyles(theme, this.wheelData);
				this.ngStyle = ngStyle;
				this.backgroundImageUrl = backgroundImageUrl;

				// Lang
				this.lang = data.campaign.lang;

				// Change footer text base on the next route of the wheel
				this.isAnyNextRoute = this.routingOrderService.getNextRoute("wheel");
				if (this.isAnyNextRoute) {
					if (this.lang === "fr") {
						this.footerBtnText = this.ctaNextText;
					} else if (this.lang === "ar") {
						this.footerBtnText = this.ctaNextText;
					}
				} else {
					if (this.lang === "fr") {
						this.footerBtnText = this.ctaRetryText;
					} else if (this.lang === "ar") {
						this.footerBtnText = this.ctaRetryText;
					}
				}

				// Get data from the local storage
				const storedWinningProduct = JSON.parse(localStorage.getItem("winningProduct"));

				// Get sources and product data
				this.inputSelectValues = storedWinningProduct?.product.sources;
				this.productData = storedWinningProduct?.product;

				// Check if we have product or not
				const isEmpty = localStorage.getItem("empty");
				if (this.productData && isEmpty === "false") {
					this.userWheelStatus = "win";

					this.userWheelProductType = this.productData.category;
					this.userWheelProductImg = this.productData.img.url;
					this.userWheelProductName = this.productData.name;

					// Check if product type and if the source have values
					if (this.userWheelProductType === "physical" && !this.inputSelectValues.length) {
						// If product type is physical
						if (!storedWinningProduct?.winProductCode) {
							this.restApiService
								.get(`lases/${this.uuid}`)
								.pipe(takeUntil(this.unsubscriber))
								.subscribe((response) => {
									const lasId = response.data?.id;

									this.restApiService
										.get(`campaigns/${storedWinningProduct.slug}/winProduct?lasId=${lasId}&winningId=${storedWinningProduct.id}&state=online`)
										.pipe(takeUntil(this.unsubscriber))
										.subscribe((response) => {
											// Store the win product code in the local storage
											storedWinningProduct.winProductCode = response.data.code;
											localStorage.setItem("winningProduct", JSON.stringify(storedWinningProduct));

											// Update the value of the local storage
											this.userWheelProductCode = storedWinningProduct.winProductCode;
										});
								});

							// this.restApiService
							// 	.get(`campaigns/${storedWinningProduct.slug}/winProduct?winningId=${storedWinningProduct.id}&&`)
							// 	.pipe(takeUntil(this.unsubscriber))
							// 	.subscribe((response) => {
							// 		// Store the win product code in the local storage
							// 		storedWinningProduct.winProductCode = response.data.code;
							// 		localStorage.setItem("winningProduct", JSON.stringify(storedWinningProduct));

							// 		// Update the value of the local storage
							// 		this.userWheelProductCode = storedWinningProduct.winProductCode;
							// 	});
						} else {
							this.userWheelProductCode = storedWinningProduct.winProductCode;
						}

						if (this.lang == "ar") {
							this.resultMessage = this.physicalWinMessage;
						} else {
							this.resultMessage = this.physicalWinMessage;
						}
					} else if (this.userWheelProductType === "voucher") {
						// If product type is voucher with no operations
						if (!this.inputSelectValues.length) {
							this.isInputHasValue = true;
							if (!storedWinningProduct.winProductCode) {
								this.restApiService
									.get(`lases/${this.uuid}`)
									.pipe(takeUntil(this.unsubscriber))
									.subscribe((response) => {
										const lasId = response.data?.id;

										this.restApiService
											.get(`campaigns/${storedWinningProduct.slug}/winProduct?lasId=${lasId}&winningId=${storedWinningProduct.id}&state=online`)
											.pipe(takeUntil(this.unsubscriber))
											.subscribe((response) => {
												// Store the win product code in the local storage
												storedWinningProduct.winProductCode = response.data.code;
												localStorage.setItem("winningProduct", JSON.stringify(storedWinningProduct));

												// Update the value of the local storage
												this.userWheelProductCode = storedWinningProduct.winProductCode;
											});
									});

								// this.restApiService
								// 	.get(`campaigns/${storedWinningProduct.slug}/winProduct?winningId=${storedWinningProduct.id}&&`)
								// 	.pipe(takeUntil(this.unsubscriber))
								// 	.subscribe((response) => {
								// 		// Store the win product code in the local storage
								// 		storedWinningProduct.winProductCode = response.data.code;
								// 		localStorage.setItem("winningProduct", JSON.stringify(storedWinningProduct));

								// 		// Update the value of the local storage
								// 		this.userWheelProductCode = storedWinningProduct.winProductCode;
								// 	});
							} else {
								this.userWheelProductCode = storedWinningProduct.winProductCode;
							}
						} else {
							if (this.lang === "fr") {
								this.footerBtnText = this.ctaNextText;
							} else if (this.lang === "ar") {
								this.footerBtnText = this.ctaNextText;
							}
						}

						if (this.lang == "ar") {
							this.resultMessage = this.voucherWinMessage;
						} else {
							this.resultMessage = this.voucherWinMessage;
						}
					}
				} else if (isEmpty === "true") {
					this.userWheelStatus = "lose";
				}
			}
		});
	}

	ngOnDestroy(): void {
		this.unsubscriber.next(null);
		this.unsubscriber.complete();
	}

	// --------------------------------------------------------------------------------------------
	// @ Events
	// --------------------------------------------------------------------------------------------
	onFooterBtnClick() {
		// If product voucher have operations
		if (this.userWheelProductType === "voucher" && this.selectedRegion !== "") {
			if (this.footerBtnText === this.ctaNextText || this.footerBtnText === this.ctaNextText) {
				this.countClick++;
				this.isInputHasValue = true;
				const storedWinningProduct = JSON.parse(localStorage.getItem("winningProduct"));

				this.restApiService
					.get(`lases/${this.uuid}`)
					.pipe(takeUntil(this.unsubscriber))
					.subscribe((response) => {
						const lasId = response.data?.id;

						this.restApiService
							.get(`campaigns/${storedWinningProduct.slug}/winProduct?lasId=${lasId}&winningId=${storedWinningProduct.id}&voucherSourceId=${this.selectedRegion}&state=online`)
							.pipe(takeUntil(this.unsubscriber))
							.subscribe((response) => {
								// Store the win product code in the local storage
								storedWinningProduct.winProductCode = response.data.code;
								localStorage.setItem("winningProduct", JSON.stringify(storedWinningProduct));

								// Update the value of the local storage
								this.userWheelProductCode = storedWinningProduct.winProductCode;

								// Change footer text base on the next route of the wheel
								this.isAnyNextRoute = this.routingOrderService.getNextRoute("wheel");
								// localStorage.setItem("hasWon", "false");

								if (this.isAnyNextRoute) {
									if (this.lang === "fr") {
										this.footerBtnText = this.ctaNextText;
									} else if (this.lang === "ar") {
										this.footerBtnText = this.ctaNextText;
									}
								} else {
									if (this.lang === "fr") {
										this.footerBtnText = this.ctaRetryText;
									} else if (this.lang === "ar") {
										this.footerBtnText = this.ctaRetryText;
									}
								}
							});
					});

				if (this.selectedRegion !== "" && this.countClick === 2) {
					this.routingOrderService.navigateToNext("wheel");
					localStorage.setItem("hasWon", "false");
				}
			} else {
				// this.router.navigate(["../policy"], { relativeTo: this.activatedRoute });
				this.routingOrderService.navigateToNext("wheel");
				localStorage.setItem("hasWon", "false");
			}
		} else {
			// this.router.navigate(["../policy"], { relativeTo: this.activatedRoute });
			this.routingOrderService.navigateToNext("wheel");
			localStorage.setItem("hasWon", "false");
		}
	}

	onRegionChange(event: Event) {
		this.selectedRegion = (event.target as HTMLSelectElement).value;
	}

	// --------------------------------------------------------------------------------------------
	// @ Function
	// --------------------------------------------------------------------------------------------
	// Checks if the app is running in standalone mode
	checkStandaloneMode(): void {
		if (window.matchMedia("(display-mode: standalone)").matches || (window.navigator as any).standalone) {
			this.isStandalone = true;
		}
	}

	// Updates the online/offline status
	updateOnlineStatus(): void {
		this.isOnline = window.navigator.onLine;
	}

	handleClipboardEvent() {
		this.isCopied = true;
	}
}
