import { Injectable } from "@angular/core";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

@Injectable({
	providedIn: "root",
})
export class FingerprintService {
	private readonly STORAGE_KEY = "device_fingerprint";
	private fpPromise = FingerprintJS.load();

	async getRandomDeviceId(): Promise<string> {
		// Generate a new fingerprint within date now and store it in the local storage
		const fp = await this.fpPromise;
		const result = await fp.get();
		const newId = result.visitorId;

		localStorage.setItem(this.STORAGE_KEY, newId);
		return newId;
	}

	async getRandomDeviceIdHostess(): Promise<string> {
		// Generate a new fingerprint within date now and store it in the local storage
		const fp = await this.fpPromise;
		const result = await fp.get();
		const newId = result.visitorId + "-" + Date.now();

		localStorage.setItem(this.STORAGE_KEY, newId);
		return newId;
	}

	async getDeviceId(): Promise<string> {
		let storedId = localStorage.getItem(this.STORAGE_KEY);

		// Check if the value is already stored
		if (storedId) {
			return storedId;
		}

		// Generate a new fingerprint and store it in the local storage
		const fp = await this.fpPromise;
		const result = await fp.get();
		const newId = result.visitorId;

		localStorage.setItem(this.STORAGE_KEY, newId);
		return newId;
	}
}
