import { NgModule } from "@angular/core";
import { CommonModule, NgStyle } from "@angular/common";
import { SharedModule } from "src/app/shared/shared.module";
import { WheelRoutingModule } from "./wheel.routing.module";
import { WheelComponent } from "./code/wheel.component";
import { ResultComponent } from "./result/result.component";
import { CdkCopyToClipboard } from "@angular/cdk/clipboard";
import { ImageLoadDirective } from "../core/directives/image-load.directive";

@NgModule({
	declarations: [WheelComponent, ResultComponent],
	imports: [WheelRoutingModule, SharedModule, NgStyle, CdkCopyToClipboard, ImageLoadDirective],
})
export class WheelModule {}
