import { Component } from "@angular/core";
import { UtilsService } from "src/app/core/services/utils.service";

@Component({
	selector: "app-toasts",
	template: `
		@for (toast of utilsService.toasts; track toast) {
			<ngb-toast class="bg-{{ toast.status }} text-light text-center" [autohide]="true" [delay]="toast.delay || 1500" (hidden)="utilsService.removeToast(toast)">
				<i class="las la-check-double"></i>&nbsp; {{ toast.message }}
			</ngb-toast>
		}
	`,
	host: { class: "toast-container position-fixed start-50 translate-middle-x pb-2", style: "z-index: 9999; top: 12px;" },
})
export class ToastsContainer {
	constructor(public utilsService: UtilsService) {}
}
