import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { LasRoutingModule } from "./las-routing.module";
import { LasComponent } from "./las.component";
import { TutorialComponent } from "./tutorial/tutorial.component";
import { GameChooserComponent } from "./game-chooser/game-chooser.component";
import { SlickCarouselModule } from "ngx-slick-carousel";
import { SharedModule } from "src/app/shared/shared.module";
import { PolicyComponent } from "./policy/policy.component";
import { SafeHtmlPipe } from "src/app/core/pipe/safe-html.pipe";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { ContentComponent } from "./content/content.component";
import { WheelModule } from "./wheel/wheel.module";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { DataCollectionComponent } from "./data-collection/data-collection.component";
import { HostessAuthComponent } from "./hostess-auth/hostess-auth.component";
import { ImageLoadDirective } from "./core/directives/image-load.directive";
import { QAComponent } from "./q&a/qa.component";

@NgModule({
	declarations: [LasComponent, PolicyComponent, TutorialComponent, GameChooserComponent, ContentComponent, DataCollectionComponent, HostessAuthComponent, QAComponent],
	imports: [
		NgxUiLoaderModule.forRoot({
			fgsColor: null, // Set foreground spinner color (e.g., orange-red)
			fgsType: null, // Spinner type (options: ball-spin-clockwise, three-bounce, etc.)
			fgsSize: 50, // Spinner size (in pixels)

			// Background spinner (optional background loader) settings
			bgsColor: null, // Background spinner color
			bgsPosition: null, // Background spinner position
			bgsSize: 60, // Background spinner size (in pixels)
			bgsType: null, // Background spinner type

			// Progress bar settings
			pbColor: "#ff073a", // Progress bar color (e.g., blue)
			pbThickness: 2,
		}),
		LasRoutingModule,
		LasRoutingModule,
		SlickCarouselModule,
		ClipboardModule,
		SharedModule,
		WheelModule,
		PdfViewerModule,
		ImageLoadDirective,
	],
	exports: [SafeHtmlPipe],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LasModule {}
