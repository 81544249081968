import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { BehaviorSubject, Observable, catchError, throwError, tap } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class LasService {
	private apiURL = environment.apiURL;
	campaignSubject = new BehaviorSubject<any>(null);
	lasSubject = new BehaviorSubject<any>(null);

	constructor(private httpClient: HttpClient) {}

	getStepPayload(data, stepName) {
		return data.campaign.steps.filter((e) => e.__component === stepName)[0];
	}

	checkAndRegisterUUID(uuid: string, hostess?: number): Observable<any> {
		return this.httpClient.get<any>(`${this.apiURL}/lases/${uuid}`).pipe(
			catchError((error) => {
				if (error.status === 404) {
					// Check if we have hostess ID or not
					if (hostess) {
						return this.registerUUID(uuid, hostess);
					} else {
						return this.registerUUID(uuid);
					}
				}
				return throwError(() => new Error(error));
			}),
			tap((response) => {
				this.lasSubject.next(response.data);
			})
		);
	}

	registerUUID(uuid: string, hostess?: number): Observable<any> {
		// Check if we have hostess ID or not
		if (hostess) {
			return this.httpClient.post(`${this.apiURL}/lases`, { data: { uuid, hostess } }).pipe(
				catchError(this.handleError),
				tap((response) => {
					this.lasSubject.next(response.data);
				})
			);
		} else {
			return this.httpClient.post(`${this.apiURL}/lases`, { data: { uuid } }).pipe(
				catchError(this.handleError),
				tap((response) => {
					this.lasSubject.next(response.data);
				})
			);
		}
	}

	logStep(lasId: number, stepLabel: string, additionalData = {}): Observable<any> {
		return this.httpClient
			.post(`${this.apiURL}/steps`, {
				data: {
					las: lasId,
					stepLabel: stepLabel,
					...additionalData,
				},
			})
			.pipe(catchError(this.handleError));
	}

	handleError(errorResponse: HttpErrorResponse) {
		console.error("Error occurred:", errorResponse);
		return throwError(() => new Error(errorResponse.message));
	}

	getLasData(): Observable<any> {
		return this.lasSubject.asObservable();
	}
}
