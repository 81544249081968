<!-- Main -->
<main [style.backgroundImage]="'url(/assets/las/images/backgrounds/hostess-bg.jpg)'">
	<!-- Header section -->
	@if (campaignHeader?.logo && true) {
		<header class="header-section">
			<h2 class="header-title" [style.textAlign]="campaignHeader?.alignment">
				<img class="header-img" [src]="backendURL + campaignHeader?.logo.url" alt="" />
			</h2>
		</header>
	}

	<!-- Content section -->
	<section class="content-container" id="content-container">
		<div class="main-content">
			<h1 class="main-content-header">Se Connecter</h1>
			@if (isHostessLogin && isStandalone) {
				<div class="choose-game">
					@for (campaign of hostessCampaigns; track campaign.id) {
						<button class="btn1" (click)="setStartUrl('/las/' + campaign.slug)"><i class="animation"></i>{{ campaign.name }}<i class="animation"></i></button>
					}
				</div>
			} @else if (isHostessLogin && !isStandalone) {
				<div class="choose-game">
					@for (campaign of hostessCampaigns; track campaign.id) {
						<button class="btn1" (click)="setStartUrlWeb('/las/' + campaign.slug)"><i class="animation"></i>{{ campaign.name }}<i class="animation"></i></button>
					}
				</div>
			} @else {
				<form [formGroup]="form" (ngSubmit)="onFooterBtnClick()" class="input-wrapper">
					<div class="input-group-login">
						<div class="input-group">
							<span class="input-group-text bg-white border-0 input-span-1">
								<img class="input-icon" src="/assets/las/images/person.svg" alt="" />
							</span>
							<input formControlName="identifier" type="text" id="loginInputId" class="form-control border-0" placeholder="Entrer ton login" autocomplete="off" />
							<span class="input-group-text bg-white border-0 input-span-2"></span>
						</div>
					</div>

					<div class="input-group-password">
						<div class="input-group">
							<span class="input-group-text bg-white border-0 input-span-1">
								<img class="input-icon" src="/assets/las/images/key.svg" alt="" />
							</span>
							<input formControlName="password" type="password" id="passwordInputId" class="form-control border-0" placeholder="Entrer ton password" autocomplete="off" />
							<span class="input-group-text bg-white border-0 input-span-2"></span>
						</div>

						<span class="input-warning-text" [class.opacity]="inputWarningMessage !== ''">
							<img class="input-icon-warning" src="/assets/las/images/exclamation.svg" alt="" />
							<span>{{ inputWarningMessage }}</span>
						</span>
					</div>

					<button type="submit" class="btn-hostess">Se Connecter</button>
				</form>
			}
		</div>
	</section>
</main>
