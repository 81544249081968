import { Directive, HostListener } from "@angular/core";

@Directive({
	selector: "[appImageLoad]",
	standalone: true,
})
export class ImageLoadDirective {
	@HostListener("load", ["$event"])
	onImageLoad(event: Event) {
		const target = event.target as HTMLImageElement;

		setTimeout(() => {
			target.classList.add("loaded");
			console.log("Image loaded successfully");
		}, 200); // 200ms delay
	}
}
