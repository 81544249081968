<main [ngStyle]="ngStyle">
	<!-- <main>
	-->
	<!-- Header section -->
	@if (campaignHeader?.logo && policyHeaderOpt) {
		<header class="header-section">
			<h2 class="header-title" [style.textAlign]="campaignHeader?.alignment">
				<img class="header-img" [src]="backendURL + campaignHeader?.logo.url" alt="" />
			</h2>
		</header>
	}

	<!-- Content section -->
	<section class="content-container" id="content-container">
		<div class="hidden-class">
			@if (modalContent) {
				<pdf-viewer
					(after-load-complete)="handlePDFProgress($event)"
					[src]="backendURL + modalContent"
					[render-text]="true"
					[original-size]="false"
					[fit-to-page]="true"
					[zoom-scale]="'page-width'"
					[page]="1"
				></pdf-viewer>
			}
		</div>

		<div class="main-content">
			<div class="policy-content">
				@if (policyData != null) {
					<div [innerHTML]="policyData?.richText | safeHtml"></div>
				}
				<ul class="cta-list p-0">
					@for (item of policyData?.items; track $index) {
						<label for="policy-item-{{ $index }}" class="checkbox">
							<div class="checkbox-input">
								<input type="checkbox" id="policy-item-{{ $index }}" [(ngModel)]="policyCheckedState[item.id]" />
								<label for="policy-item-{{ $index }}"></label>
							</div>
							<p>
								{{ item.text }}
								@if (item.textLink) {
									<span (click)="handlePDFModel($event, item.document.url)">{{ item.textLink }}</span>
								}
							</p>
						</label>
					}
				</ul>
			</div>
		</div>
	</section>

	<!-- Footer section -->
	<footer class="footer-section">
		<div class="footer-btn-next">
			<button
				class="btn btn-danger footer-btn"
				[ngStyle]="{
					color: colorCTA,
					'background-color': bgColorCTA,
					border: borderCTA
				}"
				[disabled]="!checkAllPoliciesAccepted()"
				(click)="onFooterBtnClick()"
			>
				{{ footerBtnText }}
			</button>
		</div>

		@if (policyFooterOpt) {
			<div class="footer-text-section">
				@if (footerText) {
					<div class="footer-text" [innerHTML]="footerText | safeHtml"></div>
				}
			</div>
		}
	</footer>
</main>

<!-- Modal -->
<ng-template #modalEdit let-modal>
	<form [formGroup]="">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button" class="btn-close" (click)="modal.dismiss('Cross click')"></button>
			</div>

			<div class="modal-body">
				<span *ngIf="modalPDFLoading" class="modalSpinner spinner-border text-black d-block mx-auto" style="border-width: 0.18em" role="status" aria-hidden="true"></span>
				<pdf-viewer
					[ngClass]="{ visible: !modalPDFLoading }"
					(after-load-complete)="handlePDFProgress($event)"
					[src]="backendURL + modalContent"
					[render-text]="true"
					[original-size]="false"
					[fit-to-page]="true"
					[zoom-scale]="'page-width'"
					[page]="1"
				></pdf-viewer>
			</div>
		</div>
	</form>
</ng-template>
