import { Injectable } from "@angular/core";
import jwtEncode from "jwt-encode";

@Injectable({
	providedIn: "root",
})
export class TokenService {
	private secretKey = "your-secret-key"; // Replace with a secure secret key

	constructor() {}

	generateToken(lasUniqueId: string): string {
		const expirationTime = new Date();
		expirationTime.setMinutes(expirationTime.getMinutes() + 15); // Token expires in 15 minutes

		const tokenPayload = {
			lasUniqueId: lasUniqueId,
			exp: Math.floor(expirationTime.getTime() / 1000),
		};

		return jwtEncode(tokenPayload, this.secretKey);
	}
}
