import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LasComponent } from "./las.component";
import { TutorialComponent } from "./tutorial/tutorial.component";
import { GameChooserComponent } from "./game-chooser/game-chooser.component";
import { PolicyComponent } from "./policy/policy.component";
import { ContentComponent } from "./content/content.component";
import { WheelComponent } from "./wheel/code/wheel.component";
import { ResultComponent } from "./wheel/result/result.component";
import { StatusComponent } from "./status/status.component";
import { DataCollectionComponent } from "./data-collection/data-collection.component";
import { HostessAuthComponent } from "./hostess-auth/hostess-auth.component";
import { QAComponent } from "./q&a/qa.component";
import { HostessAuthGuard } from "./core/guards/hostess-auth.guard";
import { WheelGuard } from "./core/guards/wheel.guard";
import { RouteOrderGuard } from "./core/guards/route.guard";
import { AgeVerificationGuard } from "./core/guards/age-verification.guard";
import { LasGuard } from "./core/guards/las.guard";
import { RouteOrderGameGuard } from "./core/guards/route-game.guard";

const routes: Routes = [
	{
		path: "",
		component: LasComponent,
		canActivate: [HostessAuthGuard],

		// If the route order guard is not working correctly, remove the las guard
		children: [
			{ path: "policy", component: PolicyComponent, canActivate: [AgeVerificationGuard] },
			{ path: "tutorial", component: TutorialComponent, canActivate: [RouteOrderGuard, LasGuard] },
			{ path: "game-chooser", component: GameChooserComponent, canActivate: [RouteOrderGuard, LasGuard] },
			{ path: "content", component: ContentComponent, canActivate: [RouteOrderGuard, LasGuard] },
			{ path: "Q&A", component: QAComponent, canActivate: [RouteOrderGuard, LasGuard] },
			{ path: "data-collection", component: DataCollectionComponent, canActivate: [RouteOrderGuard, LasGuard] },
			{ path: "status", component: StatusComponent },
			{ path: "hostess-auth", component: HostessAuthComponent },
			{ path: "wheel/result", component: ResultComponent },
			{
				path: "wheel",
				canActivate: [RouteOrderGuard, LasGuard, WheelGuard],
				loadChildren: () => import("./wheel/wheel.module").then((m) => m.WheelModule),
			},
			{
				path: "reveal-touch",
				canActivate: [RouteOrderGameGuard, LasGuard],
				loadChildren: () => import("./games/reveal-touch/reveal-touch.module").then((m) => m.RevealTouchModule),
			},
			{
				path: "choose-venue",
				canActivate: [RouteOrderGameGuard, LasGuard],
				loadChildren: () => import("./games/choose-venue/choose-venue.module").then((m) => m.ChooseVenueModule),
			},
			{
				path: "spot-mlb",
				canActivate: [RouteOrderGameGuard, LasGuard],
				loadChildren: () => import("./games/spot-mlb/spot-mlb.module").then((m) => m.SpotMlbModule),
			},
			{
				path: "friend-names",
				canActivate: [RouteOrderGameGuard, LasGuard],
				loadChildren: () => import("./games/friends-names/friends-names.module").then((m) => m.FriendsNamesModule),
			},
			{
				path: "friend-preferences",
				canActivate: [RouteOrderGameGuard, LasGuard],
				loadChildren: () => import("./games/friends-preferences/friends-preferences.module").then((m) => m.FriendsPreferencesModule),
			},
			{
				path: "style-pack",
				// canActivate: [RouteOrderGameGuard, LasGuard],
				loadChildren: () => import("./games/style-pack/style-pack.module").then((m) => m.StylePackModule),
			},
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class LasRoutingModule {}
