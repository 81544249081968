import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class AppStatusService {
	public isStandalone$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public isOnline$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

	constructor() {
		this.checkStandaloneMode();
		this.updateOnlineStatus();

		// Listen for standalone mode changes
		window.matchMedia("(display-mode: standalone)").addEventListener("change", this.checkStandaloneMode.bind(this));

		// Listen for online/offline events
		window.addEventListener("online", this.updateOnlineStatus.bind(this));
		window.addEventListener("offline", this.updateOnlineStatus.bind(this));
	}

	private checkStandaloneMode(): void {
		const isStandalone = window.matchMedia("(display-mode: standalone)").matches || (window.navigator as any).standalone;
		this.isStandalone$.next(isStandalone);
	}

	private updateOnlineStatus(): void {
		const isOnline = window.navigator.onLine;
		this.isOnline$.next(isOnline);
		console.log("updateOnlineStatus", isOnline);
	}
}
