import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { dateFormatPipeModule } from "../core/pipe/date-format.pipe";
import { NgbPaginationModule } from "@ng-bootstrap/ng-bootstrap";
import { SafeHtmlPipe } from "../core/pipe/safe-html.pipe";

@NgModule({
	declarations: [SafeHtmlPipe],
	imports: [CommonModule, FormsModule, ReactiveFormsModule, NgbPaginationModule, dateFormatPipeModule],
	exports: [CommonModule, FormsModule, ReactiveFormsModule, NgbPaginationModule, dateFormatPipeModule, SafeHtmlPipe],
	providers: [DatePipe],
})
export class SharedModule {}
