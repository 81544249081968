export const environment = {
	production: true,

	frontendURL: "https://pmi360.positif.ma",
	backendURL: "https://pmi360.positif.ma/backend",
	apiURL: "https://pmi360.positif.ma/backend/api",
	apiURLUC: "https://uc.positif.ma/backend/api",
	ageVerificationAppUrl: "https://yoti-pmi360.positif.ma/",
	UCToken:
		"510b7ae0f1bf83d80a294c80536d8d13b67cb23444ed66db004478720833c0a5ef2aa1178cd8b3c7971233c1525e3ff41c76901f6ca172fe24c20ce6f423718501ac52e19277ab4f5637fe5f1d169225786d8334df39f673b24ba0f726c49e17c48d1f07c2722fd28ecc3e69aa28e2d544dbf28e47cb848ae6b88ea195d0ac18",

	// =======================================
	defaultauth: "fackbackend",
	firebaseConfig: {
		apiKey: "",
		authDomain: "",
		databaseURL: "",
		projectId: "",
		storageBucket: "",
		messagingSenderId: "",
		appId: "",
		measurementId: "",
	},
};
