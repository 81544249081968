import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject, takeUntil } from "rxjs";
import { LasService } from "../core/services/las.service";
import { environment } from "src/environments/environment";

import { DeviceUUID } from "device-uuid";
import { RestApiService } from "src/app/core/services/rest-api.service";
import { RoutingOrderService } from "src/app/core/services/routing-order.service";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { PDFDocumentProxy, PDFProgressData } from "ng2-pdf-viewer";
import { ThemeService } from "../core/services/theme.service";
import { TokenService } from "../core/services/token.service";
import { FingerprintService } from "src/app/core/services/finger-print.service";
import { RouteOrderGuard } from "../core/guards/route.guard";

@Component({
	selector: "app-policy",
	templateUrl: "./policy.component.html",
	styleUrl: "./policy.component.scss",
	encapsulation: ViewEncapsulation.None,
})
export class PolicyComponent implements OnInit {
	unsubscriber: Subject<any> = new Subject<any>();
	backendURL = environment.backendURL;
	ngStyle: any;
	form: FormGroup;
	policyCheckedState: { [key: number]: boolean } = {};
	isOnline = true;
	isStandalone = false;
	policyData: any = null;
	footerBtnText: string;
	campaignHeader: any;
	footerText: string;
	policyHeaderOpt: boolean;
	policyFooterOpt: boolean;

	uuid: string = "";

	// LAS Log
	lasData: any;
	stepName: string;
	policyLogVerfication: any;
	verificationMethod: string;

	// Modal
	@ViewChild("modalEdit", { static: true }) modalEdit: ElementRef;
	modalEditRef: NgbModalRef;
	editModalSize: string;
	modalContent: string;
	modalPDFLoading: boolean = false;

	// CTA Theme
	colorCTA: string = "#fff";
	bgColorCTA: string = "teal";
	borderCTA: string = "none";

	backgroundImageUrl: string;

	// Hostess
	hostessInfo: any;

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private lasService: LasService,
		private restApiService: RestApiService,
		private routingOrderService: RoutingOrderService,
		private modalService: NgbModal,
		private themeService: ThemeService,
		private tokenService: TokenService,
		private fingerprintService: FingerprintService,
		private routeGuard: RouteOrderGuard
	) {}

	// --------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// --------------------------------------------------------------------------------------------
	async ngOnInit() {
		this.routeGuard.markRouteAsVisited("policy");

		// Get hostess info
		this.hostessInfo = localStorage.getItem("hostessInfo") ? JSON.parse(localStorage.getItem("hostessInfo")) : null;

		this.initializeLogVerfication();

		this.uuid = await this.fingerprintService.getDeviceId();

		this.lasService.campaignSubject.pipe(takeUntil(this.unsubscriber)).subscribe((data: any) => {
			if (data != null) {
				this.campaignHeader = data.campaign?.theme.header;
				this.footerText = data.campaign?.theme.footer;

				this.verificationMethod = data.campaign?.ageVerificationMethod;

				this.policyData = data.campaign.steps.filter((e) => e.__component === "screen.policy")[0];
				this.stepName = this.policyData.stepLabel;
				this.policyHeaderOpt = this.policyData.isHeaderEnabled;
				this.policyFooterOpt = this.policyData.isFooterEnabled;

				// Log step
				if (!this.hostessInfo) {
					const stepLebelFormated = this.removeSpacesBetweenWords(this.policyData.stepLabel);
					if (!this.policyLogVerfication[stepLebelFormated]) {
						this.lasService.lasSubject.pipe(takeUntil(this.unsubscriber)).subscribe((data) => {
							if (data != null) {
								this.lasData = data;
								this.logStep(this.lasData.id, this.stepName);
							}
						});

						this.updateLogVerfication(this.removeSpacesBetweenWords(this.stepName), "true");
					}
				}

				this.policyData.items.forEach((item) => {
					this.policyCheckedState[item.id] = false;
				});
				this.footerBtnText = this.policyData.ctaText;

				// Set theme --------------------
				const { ngStyle, backgroundImageUrl } = this.themeService.getThemeStyles(data.campaign?.theme, this.policyData);
				this.ngStyle = ngStyle;
				this.backgroundImageUrl = backgroundImageUrl;
			}
		});
	}

	ngOnDestroy(): void {
		this.unsubscriber.next(null);
		this.unsubscriber.complete();
	}

	// --------------------------------------------------------------------------------------------
	// @ Events
	// --------------------------------------------------------------------------------------------
	async onFooterBtnClick() {
		// Get the current path
		const currentPath = this.activatedRoute.snapshot.routeConfig.path;

		// Get the next route
		const nextRoute = this.routingOrderService.getNextRoute(currentPath);

		// Check verification method
		if (this.verificationMethod === "hav") {
			if (false) {
				const hostess = this.hostessInfo.user.id;

				// Check/register UUID in backend API within hostess ID, if not exist and get Las data from backend API
				const lasUUID = await this.fingerprintService.getRandomDeviceIdHostess();
				this.lasService
					.registerUUID(lasUUID, hostess)
					.pipe(takeUntil(this.unsubscriber))
					.subscribe({
						next: (response) => {
							// Update las UUID in the localStorage
							localStorage.setItem("device_fingerprint", lasUUID);

							// Log step
							this.updateLogVerfication(this.removeSpacesBetweenWords(this.stepName), "true");
							this.logStep(response.data.id, this.stepName);
						},
						error: (error) => {
							console.error("Error checking/registering UUID:", error);
						},
						complete: () => {
							// Navigate to the next route
							this.routingOrderService.navigateToNext(currentPath);
						},
					});
			} else {
				// Navigate to the next route
				this.routingOrderService.navigateToNext(currentPath);
			}
		} else if (this.verificationMethod === "yoti") {
			this.restApiService
				.get(`lases/${this.uuid}`)
				.pipe(takeUntil(this.unsubscriber))
				.subscribe((response) => {
					const { isAgeVerified } = response.data;

					if (isAgeVerified) {
						// Navigate to the next route
						this.routingOrderService.navigateToNext(currentPath);
					} else {
						this.activatedRoute.queryParamMap.subscribe((params) => {
							// Get verification result & token from the url
							const yotiVerification = params.get("yotiVerification");
							const token = params.get("token");

							// Check if yotiVerification & token is not found
							if (!(yotiVerification && token)) {
								try {
									// Generate a secure token
									const token = this.tokenService.generateToken(this.uuid);

									// Get the redirectUrl of the next route
									const redirectUrl = `${this.router.url}`.replace(currentPath, nextRoute);

									// Construct the coded redirect URL
									const encodedRedirectUrl = encodeURIComponent(`${window.location.origin}${redirectUrl}`);

									// Construct the age verification app URL with parameters
									const ageVerificationUrl = `${environment.ageVerificationAppUrl}?redirectToUrl=${encodedRedirectUrl}&token=${token}`;

									// Call set yoti token
									this.restApiService
										.put("/lases/set-token", this.uuid, {
											yotiToken: token,
										})
										.pipe(takeUntil(this.unsubscriber))
										.subscribe((response) => {
											// Check upcoming response
											console.log(response);
										});

									// Redirect to the age verification app
									window.location.href = ageVerificationUrl;
								} catch (error) {
									console.error("Error generating token:", error);
									// Handle the error appropriately
								}
							}
						});
					}
				});
		}
	}

	onPolicyClick(e: MouseEvent) {
		e.preventDefault();
		e.stopPropagation();

		console.log("Policy link clicked");
	}

	handlePDFModel(e: MouseEvent, url: string) {
		e.preventDefault();
		e.stopPropagation();

		this.modalContent = url;
		this.modalEditRef = this.modalService.open(this.modalEdit, { size: this.editModalSize, centered: true });

		this.modalPDFLoading = true;
	}

	handlePDFProgress(pdf: PDFDocumentProxy) {
		// Check if the pdf is loaded
		if (pdf) {
			setTimeout(() => {
				this.modalPDFLoading = false;
			}, 400);
		}
	}

	// --------------------------------------------------------------------------------------------
	// @ Function
	// --------------------------------------------------------------------------------------------
	checkAllPoliciesAccepted(): boolean {
		return Object.values(this.policyCheckedState).every((value) => value === true);
	}

	logStep(lasId: number, stepLabel: string) {
		this.lasService
			.logStep(lasId, stepLabel)
			.pipe(takeUntil(this.unsubscriber))
			.subscribe({
				next: (response) => {
					console.log("Journey policy created:", response);
				},
				error: (error) => {
					console.error("Error creating journey:", error);
				},
			});
	}

	// Initialize LogVerfication & Update the component log
	private initializeLogVerfication() {
		const storedState = localStorage.getItem("logVerfication");
		this.policyLogVerfication = storedState ? JSON.parse(storedState) : {};
	}

	private updateLogVerfication(key: string, value: any) {
		this.policyLogVerfication[key] = value;
		localStorage.setItem("logVerfication", JSON.stringify(this.policyLogVerfication));
	}

	// Remove spaces from the text
	removeSpacesBetweenWords(text: any) {
		return text.replace(/(\S)\s+(\S)/g, "$1$2");
	}
}
