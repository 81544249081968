import { Component, OnInit } from "@angular/core";

@Component({
	selector: "app-las-layout",
	templateUrl: "./las-layout.component.html",
})
export class LasLayoutComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
