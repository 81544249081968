import { Injectable } from "@angular/core";

@Injectable({
	providedIn: "root",
})
export class RoutePositionService {
	constructor() {}

	getRouteOrderPosition(currentRoute: string): boolean {
		const routeOrder = JSON.parse(localStorage.getItem("routeOrder") || "[]");

		if (routeOrder?.includes(currentRoute)) {
			const isLastPosition = routeOrder[routeOrder.length - 1] === currentRoute;

			console.log("is the final step: ", isLastPosition);
			return isLastPosition;
		}

		return false;
	}
}
