import { Component, OnInit, Output, ViewEncapsulation, EventEmitter, ViewChild, ElementRef } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Subject, takeUntil } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { LasService } from "../core/services/las.service";
import { environment } from "src/environments/environment";
import { RoutingOrderService } from "src/app/core/services/routing-order.service";
import { ThemeService } from "../core/services/theme.service";
import { RouteOrderGuard } from "../core/guards/route.guard";
import { RoutePositionService } from "../core/services/route.position.service";

@Component({
	selector: "app-tutorial",
	templateUrl: "./tutorial.component.html",
	styleUrl: "tutorial.component.scss",
	encapsulation: ViewEncapsulation.None,
})
export class TutorialComponent implements OnInit {
	unsubscriber: Subject<any> = new Subject<any>();
	backendURL = environment.backendURL;
	ngStyle: any;
	form: FormGroup;

	tutorialData: any;
	footerBtnText: string;
	footerText: string;
	campaignHeader: any;
	tuturialHeaderOpt: boolean;
	tuturialFooterOpt: boolean;

	// LAS Log
	lasData: any;
	stepName: string;
	tutorialLogVerfication: any;

	// CTA Theme
	colorCTA: string = "#fff";
	bgColorCTA: string = "teal";
	borderCTA: string = "none";

	backgroundImageUrl: string;

	// Final Step
	isFinalStep: boolean = false;

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private lasService: LasService,
		private routingOrderService: RoutingOrderService,
		private themeService: ThemeService,
		private routeGuard: RouteOrderGuard,
		private routePositionService: RoutePositionService
	) {}
	@ViewChild("mainElement", { static: true }) mainElement: ElementRef;

	// --------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// --------------------------------------------------------------------------------------------
	ngOnInit(): void {
		this.routeGuard.markRouteAsVisited("tutorial");

		// Check if this route is the final step
		this.isFinalStep = this.routePositionService.getRouteOrderPosition("tutorial");

		this.initializeLogVerfication();

		this.lasService.campaignSubject.pipe(takeUntil(this.unsubscriber)).subscribe((data: any) => {
			if (data != null) {
				this.campaignHeader = data.campaign.theme.header;
				this.footerText = data.campaign.theme.footer;

				this.tutorialData = data.campaign.steps.filter((e) => e.__component === "screen.tutorial")[0];
				this.tuturialHeaderOpt = this.tutorialData.isHeaderEnabled;
				this.tuturialFooterOpt = this.tutorialData.isFooterEnabled;

				// Log step
				const stepLebelFormated = this.removeSpacesBetweenWords(this.tutorialData.stepLabel);
				if (!this.tutorialLogVerfication[stepLebelFormated]) {
					this.stepName = this.tutorialData.stepLabel;
					this.lasService.lasSubject.pipe(takeUntil(this.unsubscriber)).subscribe((data) => {
						if (data != null) {
							this.lasData = data;
							this.logStep(this.lasData.id, this.stepName, { ...(this.isFinalStep && { isFinalStep: true }) });
						}
					});

					this.updateLogVerfication(this.removeSpacesBetweenWords(this.stepName), "true");
				}

				this.footerBtnText = this.tutorialData.ctaText;

				// Set theme --------------------
				const { ngStyle, backgroundImageUrl } = this.themeService.getThemeStyles(data.campaign?.theme, this.tutorialData);
				this.ngStyle = ngStyle;
				this.backgroundImageUrl = backgroundImageUrl;
			}
		});
	}

	ngOnDestroy(): void {
		this.unsubscriber.next(null);
		this.unsubscriber.complete();
	}
	// --------------------------------------------------------------------------------------------
	// @ Events
	// --------------------------------------------------------------------------------------------
	onFooterBtnClick() {
		// If is final step remove the log verfication
		if (this.isFinalStep) {
			localStorage.removeItem("logVerfication");
			localStorage.removeItem("visitedRoutes");
		}

		const currentPath = this.activatedRoute.snapshot.routeConfig.path;
		// this.routingOrderService.navigateToNext(currentPath);
		this.router.navigate(["../style-pack"], { relativeTo: this.activatedRoute });
	}

	onImageLoad(event) {
		event.target.classList.add("loaded");
	}

	// --------------------------------------------------------------------------------------------
	// @ Function
	// --------------------------------------------------------------------------------------------
	logStep(lasId: number, stepLabel: string, additionalData = {}) {
		this.lasService
			.logStep(lasId, stepLabel, additionalData)
			.pipe(takeUntil(this.unsubscriber))
			.subscribe({
				next: (response) => {
					console.log("Journey tutorial created:", response);
				},
				error: (error) => {
					console.error("Error creating journey:", error);
				},
			});
	}

	// Initialize LogVerfication & Update the component log
	private initializeLogVerfication() {
		const storedState = localStorage.getItem("logVerfication");
		this.tutorialLogVerfication = storedState ? JSON.parse(storedState) : {};
	}
	private updateLogVerfication(key: string, value: any) {
		this.tutorialLogVerfication[key] = value;
		localStorage.setItem("logVerfication", JSON.stringify(this.tutorialLogVerfication));
	}

	// Remove spaces from the text
	removeSpacesBetweenWords(text: any) {
		return text.replace(/(\S)\s+(\S)/g, "$1$2");
	}
}
