import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { NgbDropdownModule, NgbNavModule, NgbToastModule } from "@ng-bootstrap/ng-bootstrap";
import { SimplebarAngularModule } from "simplebar-angular";
import { LanguageService } from "../core/services/language.service";
import { TranslateModule } from "@ngx-translate/core";
import { NgbCollapseModule } from "@ng-bootstrap/ng-bootstrap";

// Component pages
import { AdminLayoutComponent } from "./layout.component";
import { VerticalComponent } from "./vertical/vertical.component";
import { TopbarComponent } from "./topbar/topbar.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { FooterComponent } from "./footer/footer.component";

// Load Icons
import { defineElement } from "@lordicon/element";
import lottie from "lottie-web";
import { ToastComponent } from "../shared/components/toast/toast.component";
import { ToastsContainer } from "../shared/components/toasts-container/toasts-container.component";
import { LasLayoutComponent } from "./las-layout/las-layout.component";
import { FeatherModule } from "angular-feather";
import { allIcons } from "angular-feather/icons";
import { SigninLayoutComponent } from "./signin-layout/signin-layout.component";

@NgModule({
	declarations: [
		// Primary components ===================
		AdminLayoutComponent,
		LasLayoutComponent,
		SigninLayoutComponent,

		// Secondary components =================
		VerticalComponent,
		TopbarComponent,
		SidebarComponent,
		FooterComponent,
		ToastComponent,
		ToastsContainer,
	],
	imports: [
		CommonModule,
		RouterModule,
		NgbDropdownModule,
		NgbNavModule,
		SimplebarAngularModule,
		TranslateModule,
		FormsModule,
		NgbCollapseModule,
		ReactiveFormsModule,
		NgbToastModule,
		FeatherModule.pick(allIcons),
	],

	providers: [LanguageService],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LayoutsModule {
	constructor() {
		defineElement(lottie.loadAnimation);
	}
}
